<template>
<div>
  <v-select ref="build-select" multiple
            v-model="selected" :disabled="disabled"
            :filterable="false" :options="options" @search="onSearch"
            style="min-width: 150px; width: 100%"
  >
    <template #option="{label}">
      {{ buildings[label].address }}
    </template>
    <template #selected-option="{label}">
      <div>
        <div v-if="buildings[label]">
          {{ buildings[label].address }}
        </div>
        <div v-else>
          ??
        </div>
      </div>
    </template>
    <template #list-header>
      <div class="text-right" v-if="optionsPages > 1 && loading !== true">
        <b-button variant="outline-primary" size="sm" @click="updatePage(-1)" v-if=" optionsPage > 1" :disabled="loading">Prev</b-button>
        <span>({{optionsPage}}/{{optionsPages}})</span>
        <b-button variant="outline-primary" size="sm"  @click="updatePage(1)" v-if="optionsPage < optionsPages"  v-bind:disabled="loading">Next</b-button>
      </div>
      <div class="text-right" v-if="optionsPages > 1 && loading !== false">
        <b-spinner small></b-spinner>
      </div>
    </template>
  </v-select>
</div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {Debounce} from "@core/utils/utils";

export default {
  name: "buildingSelectBox",
  props: {
    value: {
      required: true,
    },
    selectProps: {
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    org:{},
    clearable: {
      type: Boolean,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    paginationSize: {
      type: Number,
      default: () => 10
    },
  },
  watch:{
    value: {
      handler: function (val) {
        this.refreshAllBuildings()
      },
      immediate: true
    },
  },
  data: () => ({
    buildings: {},
    options: [],
    optionsPages: 0,
    optionsPage: 1,
    searchInput: '',
    loading: false,
    error: '',
    refreshOptionsDebounce: Debounce(() => {
    }, 500),
  }),
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  created() {
    this.refreshOptionsDebounce = Debounce(this.refreshOptions, 500)
    this.refreshOptions();
  },
  methods: {
    ...mapActions('building', ['getAllBuildings']),
    refreshAllBuildings(){
      let selected = (this.selected || []).filter(id => this.buildings[id] == null);
      let $this = this
      if(selected.length <= 0){
        return;
      }
      this.loading = true
      this.getAllBuildings({ filter: { ids: selected } })
          .then(data => {
            this.options = data.Buildings.forEach(building => {
              $this.buildings[building.id] =building
              return building.id
            })
            $this.loading = false
          })
    },
    refreshOptions(onCompleted) {
      let $this = this
      console.log('refreshOptions', $this.searchInput)
      let request = {
        filter: { like: {address: $this.searchInput} },
        ...{ page: $this.optionsPage, amount: $this.paginationSize }
      };
      this.getAllBuildings(request)
          .then(data => {
            let buildings = data.Buildings;
            this.optionsPages = data.TotalRows / data.Amount;
            this.options = buildings.map(building => {
              $this.buildings[building.id] = building
              return building.id
            })
          })
          .finally(() => {
            if (onCompleted) {
              onCompleted()
            }
            $this.loading = false
          })
    },
    updatePage(page) {
      this.loading = true
      this.optionsPage += page;
      this.refreshOptionsDebounce(() => this.loading = false)
    },
    onSearch(search, loading) {
      loading(true)
      console.log('onSearch', search)
      this.optionsPage = 1;
      this.searchInput = search
      this.refreshOptionsDebounce(() => loading(false))
    }
  }
}
</script>



<style scoped>

</style>
